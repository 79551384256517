import React from 'react';
import { useState, useEffect } from 'react';
import netlifyIdentity from 'netlify-identity-widget'
import { navigate } from 'gatsby';

const useNetlifyIdentity = () => {

    let redirected;

    if (typeof window !== "undefined") {
        const searchParams = new URLSearchParams(window.location.search);
        redirected = searchParams.get('redirected_from');
    }



    //------------------------------------------------------------------------------------------------------
    // Netlify Identity related code -------------------------------------------------------


    //variables for states of user, or any error that may occur 
    const [user, setUser] = useState(null)
    const [error, setError] = useState(null)

    //state boolean to indicate whether Netlify identity is ready to be used. NEEDS TO BE 'false' for production! If this causes trouble in development mode, put it to 'true' for the time being.
    const [authReady, setAuthReady] = useState(false)



    //a useEffect that only runs once, getting ready all the things we need for Netlify identity
    useEffect(() => {


        //event listener: what should happen when someone logs in
        netlifyIdentity.on('login', (user) => {

            // console.log('login event')

            //set user state to save user info
            setUser(user)


            //close Netlify login dialogue
            netlifyIdentity.close()


            if (user && user.app_metadata && user.app_metadata.roles && user.app_metadata.roles[0] === 'authenticatedUser') {

                const currentPath = window.location.pathname

                if (currentPath === '/') {
                    // console.log('forwarding existing user (before granted function)')
                    // console.log('navigating to main 1')

                    if (redirected) {

                        // console.log('redirectedFrom exists, namely:')
                        // console.log(redirected)
                        navigate(redirected)
                    }

                    else {
                        // console.log('just forwarding to main')
                        navigate('/main')
                    }
                }

                return


            }


            //creating a url to call the 'getaccess' function that includes the (to be checked) user's email
            const urlString = '/.netlify/functions/check-access'

            const token = user && user.token.access_token

            // calling the function with said url
            fetch(urlString, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(res => {

                    //throwing error if no result could be generated
                    if (!res.ok) {
                        throw Error('Log in to see content')
                    }

                    //otherwise return result and json'ify it
                    return res.json()
                })

                .then(data => {

                    //if access granted, we navigate to the protected page
                    if (data.granted) {

                        //check if current path is /
                        const currentPath = window.location.pathname

                        if (currentPath === '/' && data.authenticatedUser) {
                            // console.log('navigating to main 2')
                            if (redirected) {

                                // console.log('redirectedFrom exists, namely:')
                                // console.log(redirected)
                                navigate(redirected)
                            }

                            else {
                                // console.log('just forwarding to main')
                                navigate('/main')
                            }
                        }

                    }

                    //if function did NOT grant access, we set access/error/loginFailed states accordingly and log out user
                    else {
                        netlifyIdentity.logout()
                        netlifyIdentity.close()
                        alert('Access denied.');

                    }
                })

                //catch error if there is one
                .catch((err) => {
                    setError(err.message)
                })



            // console.log('login event')
            // console.log('user is: ' + JSON.stringify(user))

        })

        //event listener: what should happen when someone logs out
        netlifyIdentity.on('logout', () => {

            //set user state back to null
            setUser(null)
            // console.log('logout event')

            // console.log('navigating to /')
            navigate('/')


        })

        //event listener: what should happen once Netlify identity has been initialized
        netlifyIdentity.on('init', (user) => {

            //set user state to save user info. this may seem redundant but appeared to be necessary - I believe it's for instances where the page gets reloaded and a user still exists (saved in cookie), so we need to set a user not based on a login event but just based on a (re-)initialization of Netlify identity
            setUser(user)

            //set boolean state to indicate we are ready for someone to login 
            setAuthReady(true)
            // console.log('init event')

        })


        //initialize Netlify identity with first render. Note: it's important to initialize Netlify identity only here, AFTER we have defined all the above event listeners.

        netlifyIdentity.init()


    }, [])

    //use these variables for onClick actions (click on button x to log in)
    const login = () => {
        netlifyIdentity.open()
    }

    //use these variables for onClick actions (click on button x to log oout)

    const logout = () => {
        netlifyIdentity.logout()

    }


    const [cloudRunToken, setCloudrunToken] = useState(null)

    function generateCloudRunToken() {

        netlifyIdentity.refresh().then(res => {

            const urlString = '/.netlify/functions/get-token'

            // calling the function with said url
            fetch(urlString,
                {
                    headers: {
                        'Authorization': `Bearer ${res}`
                    }
                }
            )
                .then(res => {

                    //throwing error if no result could be generated
                    if (!res.ok) {
                        throw Error('Log in to see content')
                    }

                    //otherwise return result and json'ify it
                    return res.json()
                })
                .then(data => {

                    setCloudrunToken(data.token)
                })



        })




    }


    //END of Netlify Identity related code
    //----------------------------------------------------------------------------------------------------------


    return { authReady, user, error, login, logout, cloudRunToken, generateCloudRunToken }
}

export default useNetlifyIdentity;